//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Detection from "@/components/detection/detection";
import CustomerInfo from "@/components/customerInfo/customerInfo.vue";
import LeftBar from "@/components/left-bar/left-bar.vue";
import lawApi from "@/api/law";
export default {
  components: {
    LeftBar,
    Detection,
  },
  data() {
    return {
      lawList: null,
      rulesList: null,
      lawsLoading: false,
      rulesLoading: false,
      currentChooseNode: null,
      action: {
        barShow: false,
      },
      currentMenuIndex: null,
      currentLawIndex: 0,
      menulist: ["内容咨询", "法律法规"],
    };
  },
  mounted() {
    let user = this.$store.state.currentUser;
    if (user.customerName == null || user.customerName == "") {
      this.$message.error("请先维护个人信息");
      this.showCustomerInfo();
      return;
    }
    this.action.barShow = true;
    this.lawsLoading = true;
    this.rulesLoading = true;
    lawApi.getLaw({ type: "法律" }).then((res) => {
      if (res) {
        // let tree = getTree(res, "leaf", false, "id", "pid");
        // this.lawTree = tree;
        this.lawList = res;
        this.lawsLoading = false;
        if (res[0]) {
          lawApi.getLaw({ pid: res[0].objectid }).then((res) => {
            if (res) {
              console.log(res);
              this.rulesList = res;
              this.rulesLoading = false;
            }
          });
        }
      }
    });
    this.checkMenu(this.menulist[0]);
  },
  methods: {
    barAnime(el, done) {
      this.anime({
        targets: el,
        translateY: [-400, 0],
        opacity: [0, 1],
        easing: "easeOutQuint",
        duration: 1600,
        delay: 100,
        complete() {
          done();
        },
      });
    },
    detecEnter(el, done) {
      this.anime({
        targets: el,
        translateY: [-400, 0],
        opacity: [0, 1],
        easing: "easeOutQuint",
        duration: 1600,
        delay: 100,
        complete() {
          done();
        },
      });
    },
    detecLeave(el, done) {
      this.anime({
        targets: el,
        translateY: [0, -400],
        opacity: [1, 0],
        easing: "easeOutQuint",
        duration: 1600,
        delay: 100,
        complete() {
          done();
        },
      });
    },
    lawEnter(el, done) {
      this.anime({
        targets: el,
        translateY: [-400, 0],
        opacity: [0, 1],
        easing: "easeOutQuint",
        duration: 1600,
        delay: 100,
        complete() {
          done();
        },
      });
    },
    lawLeave(el, done) {
      this.anime({
        targets: el,
        translateY: [0, -400],
        opacity: [1, 0],
        easing: "easeOutQuint",
        duration: 1600,
        delay: 100,
        complete() {
          done();
        },
      });
    },
    checkMenu(e) {
      if (e == "法律法规" && this.$refs.detection) {
        this.$refs.detection.reset();
      }
      this.currentMenuIndex = e;
    },
    handleLawClick(item, index) {
      this.rulesLoading = true;
      this.currentLawIndex = index;
      console.log(index)
      lawApi.getLaw({ pid: item.objectid }).then((res) => {
        if (res) {
          console.log(res);
          this.rulesList = res;
          this.rulesLoading = false;
        }
      });
    },
    showCustomerInfo() {
      let that = this;
      that.$layer.iframe({
        scrollbar: false,
        content: {
          content: CustomerInfo, //传递的组件对象
          parent: that, //当前的vue对象
          data: {
            close: () => {
              that.$layer.closeAll();
            },
          }, //props
        },
        shadeClose: false,
        // maxmin: true,//开启最大化最小化
        shade: true,
        resize: false, //是否允许拉伸，默认是不允许
        area: ["610px", "640px"],
        title: `个人信息`,
        cancel: () => {
          this.$router.back()
        },
      });
    },
  }
};
