import request from '@/utils/request'
import qs from 'qs'

export function getLaw(params) {
    return request({
        url: `/api/front/law`,
        method: 'get',
        params
    })
}


export default { getLaw}