import request from '@/utils/request'

export function detect(data) {
  return request({
    url: '/detect/words',
    method: 'POST',
    data
  })
}


export default { detect}
