//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import selection from "./selection";
import detectApi from "@/api/detection";
export default {
  data() {
    return {
      options: selection,
      value: "",
      text: "",
      detectHtml: null,
      result: null,
      currentBtn: 0,
      isResultPage: false,
      collapseList:[],
    };
  },
  methods: {
    reset() {
      this.value = "";
      this.text = "";
      (this.detectHtml = null), (this.result = null);
      this.currentBtn = 0;
      this.isResultPage = false;
    },
    detecEnter(el, done) {
      this.anime({
        targets: el,
        translateX: [-200, 0],
        opacity: [0, 1],
        easing: "linear",
        duration: 200,
        complete() {
          done();
        },
      });
    },
    resultEnter(el, done) {
      this.anime({
        targets: el,
        translateX: [-200, 0],
        opacity: [0, 1],
        easing: "linear",
        duration: 200,
        complete() {
          done();
        },
      });
    },
    submit() {
      this.validator();
      detectApi
        .detect({ sentence: this.text, industry: this.value.value })
        .then((res) => {
          console.log(res);
          if (res && res.code == 1) {
            this.result = res.data;
            let temptext = this.text;
            let keywords = [];

            /**
             * 关键词对象数组排序
             */
            res.data.risk_word_list.sort(this.riskWordSort);

            console.log("res.data.risk_word_list",res.data.risk_word_list);

            /**
             * 关键词排序字符排序
             */
            res.data.word_rank_inspection.map((e) => {
              keywords = keywords.concat(keywords, e.word_list);
            });
            keywords.sort(this.stringSort);

            this.collapseList = keywords;

            console.log("keywords", keywords);

            keywords.forEach((element, index) => {
              let reg = new RegExp(element, "g");
              temptext = temptext.replace(
                reg,
                `<span style=\'color:red\'>${element}<sup style=\'color:#436ef3\'>[${
                  index + 1
                }]</sup></span>`
              );
            });
            console.log(temptext);
            this.detectHtml = temptext;
            this.isResultPage = !this.isResultPage;
          }
        });
    },
    changeBtn(e) {
      this.text = "";
      this.value = "";
      this.currentBtn = e;
    },
    stringSort(a, b) {
      if (this.text == null || this.text == "") return;
      let string = this.text;
      if (string.indexOf(a) < 0 || string.indexOf(b) < 0) {
        return;
      }
      return string.indexOf(a) - string.indexOf(b);
    },
    riskWordSort(a, b) {
      if (this.text == null || this.text == "") return;
      let string = this.text;
      if (string.indexOf(a.word) < 0 || string.indexOf(b.word) < 0) {
        return;
      }
      return string.indexOf(a.word) - string.indexOf(b.word);
    },
    selectChange(e) {
      console.log(e);
    },
    validator() {
      if (!this.text || !this.value) {
        this.$message.error("请输入待检测文字或选择行业");
        throw new Error("请输入待检测文字或选择行业");
      }
      return;
    },
  },
};
