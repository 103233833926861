export default [
    {
        value: "makeup",
        label: "化妆品",
    },
    {
        value: "estate",
        label: "房产",
    },
    {
        value: "food",
        label: "食品",
    },
    {
        value: "healthcare",
        label: "保健用品、保健服务",
    },
    {
        value: "car",
        label: "汽车、汽车用品以及服务",
    },
    {
        value: "alcohol",
        label: "酒",
    },
    {
        value: "clothes",
        label: "服饰",
    },
    {
        value: "financial",
        label: "金融、保险",
    },
    {
        value: "digital",
        label: "电子产品（非家用电器）",
    },
    {
        value: "trip",
        label: "住宿、餐饮、旅游",
    },
    {
        value: "movie",
        label: "影视、娱乐",
    },
    {
        value: "furniture",
        label: "家具、装潢装饰、日用百货",
    },
    {
        value: "education",
        label: "教育机构",
    },
    {
        value: "medicine",
        label: "药品",
    },
    {
        value: "mdinstitution",
        label: "医疗机构",
    },
    {
        value: "mdinstrument",
        label: "医疗器械",
    },
    {
        value: "accessoris",
        label: "手表、珠宝、饰品、工艺美术品",
    },
    {
        value: "other",
        label: "其他",
    },
]